<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Achat de tokens</h2>
		</div>
		<h2>
			Principe de base</h2>
		<p>
			Une personne désireuse d'acheter un token en utilisant l'unité de compte de l'écosystème Fraction.re (le fcEURO)
			va émettre un ordre d'achat sur la place de marché.


			<br /> <br />
			L'ordre d'achat est très simple à soumettre, il suffit d'indiquer la quantité à acheter et valider.
			Par défaut, le prix unitaire correspond au cours de référence du token, mais il peut être modifié.

		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Léa veut acheter 100 tokens RE59000F001 et 100 tokens RE59100F001.
			<br /> Le token RE59000F001 correspond à une maison à Lille, il n'y a plus de tokens disponibles sur le marché primaire,
			mais elle espère pouvoir en acquérir sur le marché secondaire. Si elle n'en obtient pas, elle annulera l'ordre d'achat.
			<br /> Le token RE59100F001 correspond à une maison à Roubaix, la vente initiale vient de commencer, il y a donc encore des tokens disponibles sur le marché primaire.

			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/buy-1.png" />
				<br /> <br /> <b> Le compte de Léa</b><br />
			</div>

		</div>


		<h3 class="mt-12">
			Ordre d'achat du RE59000F001</h3>


		<p>Dans la section <b>Achat</b>.</p>


		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/buy-2.png" />
				<br /> <br /> <b> Léa sélectionne le menu Achat et arrive sur la liste des tokens disponibles s'affiche </b><br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/buy-3.png" /><br />
				<br /><b>Léa sélectionne le token RE59000F001, le menu des ordres s'affiche, elle clique sur ORDRE D'ACHAT </b>
			</div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/buy-4.png" /><br />
				<br /><b>Léa indique le nombre de token à acheter, conserve le prix du jour et valide. </b>
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/buy-5.png" /><br />
				<br /><b> Il n'y avait pas de token disponible immédiatement, son ordre d'achat et donc en attente </b>
			</div>


		</div>

		<h3 class="mt-12">
			Ordre d'achat du RE59100F001</h3>
		<p> Léa va passer un ordre d'achat pour la maison de Roubaix.</p>
		<div class="row ">

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/buy-6.png" /><br /> <br /><b> Léa sélectionne le menu Achat et arrive sur la liste des tokens disponibles s'affiche </b></div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/buy-8.png" /><br /> <br /><b>Léa sélectionne le token RE59100F001, le menu des ordres s'affiche, elle clique sur ORDRE D'ACHAT </b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/buy-7.png" /><br /> <br />
				<b>Léa indique le nombre de token à acheter, conserve le prix du jour et valide. </b>
			</div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/buy-9.png" /><br />
				<br /><b>Cette fois, l'ordre a été honoré immédiatement, Léa a reçu ses 100 tokens.
					<br />
					On notera que son compte n'a été débité que de 10.000 fcEURO bien que l'ordre portait sur 10.209 fcEURO car les tokens ont été mis en vente à 100 fcEURO </b>
			</div>



		</div>

		<h3 class="mt-12">
			Suppression de l'ordre d'achat du RE59000F001</h3>
		<p> Léa décide de supprimer l'ordre d'achat de la maison de Lille qui est en attente.</p>
		<div class="row ">


			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/buy-10.png" /><br /> <br /><b>
					Léa sélectionne l'ordre d'achat dans la liste des ordres en attente </b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/buy-11.png" /><br /> <br /><b>Le Formulaire avec son numéro d'ordre s'affiche et Léa met 0 en quantité, puis valide</b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/buy-12.png" /><br /> <br /><b>L'odre d'achat a été annulé</b></div>

		</div>



		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Léa a payé 0.00001 XLM (ici à 0.20 euro pour 1 XLM ) pour l'ordre d'achat du RE59000F001, puis 0.00001 XLM pour l'ordre d'achat du token RE59100F001 et 0.00001 XLM pour l'annulation de l'ordre d'achat en attente
				<br /> <br /> Au final 0.00003 XLM en tout, soit moins de 1 centime d'euro.
			</p>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
